import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDeleteImageMutation } from 'api/control-body-survey';
import Textarea from 'components/base-components/Textarea/Textarea';
import { t } from 'i18next';
import { Field, useFormState } from 'react-final-form';
import Input from 'components/base-components/Input';
import IconButton from 'components/base-components/IconButton';
import SelectBox from 'components/base-components/SelectBox';
import Icon from 'components/base-components/Icon';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import Card from 'components/base-components/Card';
import classNames from 'classnames';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import Spinner from 'components/base-components/Spinner/Spinner';
import { useGetOfflineSesstionsQuery } from 'api/control-body-survey/offline-sessions';
import { useGetCustomVatPercentageReasonsQuery } from 'api/control-body-survey';
import { boolToYesNo } from 'utils/yes-no-helper';
import YesNoInput from 'components/base-components/YesNoInput';
import { TEMPORARY } from 'utils/control-body-survey';
import RandomGoodsDeclarationInfo from '../../negative-reports/helpers/random-goods-declaration-info.component';

const RandomDeclarationComponent = ({
  goodsDeclarationEvaluation,
  uploadedFiles,
  errorMessage,
  isFetching,
  handleUploadFiles,
  handleUploadFilteredFiles,
  isAuditor,
  isCertifier,
  randomDeclarationsComment,
  status,
  surveyId,
}) => {
  const [verifyInputDataLists, setVerifyInputDataLists] = useState([]);
  const [evaluationPeriods, setEvaluationPeriods] = useState([]);
  const [deleteImage] = useDeleteImageMutation();

  const formState = useFormState();

  const { data: offlineSessions = [], isSuccess: isOfflineSessionsFetched } =
    useGetOfflineSesstionsQuery({
      surveyId,
      session_for: 'goods_declaration_evaluation',
    });

  const { data: customVatReasons = [] } = useGetCustomVatPercentageReasonsQuery();

  const customVatReasonsOptions = customVatReasons.map(reason => ({
    label: t(`control_body.survey.options.vat_percentage_reasons.${reason}`),
    value: reason,
  }));

  const offlineSession = isOfflineSessionsFetched && offlineSessions[0];

  const maxDeclarationStatus = (() => {
    if (!goodsDeclarationEvaluation?.randomGoodsDeclarations || verifyInputDataLists.length === 0) {
      return null;
    }

    const STATUS_WEIGHT = { good: 1, average: 2, bad: 3 };

    let maxStatus = verifyInputDataLists[0].differenceStatus;

    verifyInputDataLists.forEach(input => {
      if (STATUS_WEIGHT[input.differenceStatus] > STATUS_WEIGHT[maxStatus]) {
        maxStatus = input.differenceStatus;
      }
    });

    return maxStatus;
  })();

  const hasAnyBadStatus = verifyInputDataLists.some(good => good.differenceStatus === 'bad');
  const hasAnyAvgStatus = verifyInputDataLists.some(good => good.differenceStatus === 'average');
  const hasAnyGoodStatus = verifyInputDataLists.some(good => good.differenceStatus === 'good');

  const hasAnyGoodsDeclaration = hasAnyBadStatus || hasAnyGoodStatus || hasAnyAvgStatus;

  const handleImageDelete = id => {
    deleteImage({
      attachmentId: id,
    });
  };

  const handleVerifyInputAmount = input => {
    if (input !== undefined) {
      if (verifyInputDataLists.some(data => data.id === input.id)) {
        const filteredList = verifyInputDataLists.filter(list => list.id !== input.id);
        setVerifyInputDataLists(filteredList);
        setVerifyInputDataLists(prev => [...prev, input]);
      } else {
        setVerifyInputDataLists(prev => [...prev, input]);
      }
    }
  };

  const uploadCounts = { bad: 15, average: 10, good: 5 };
  const requiredFilesToUpload = uploadCounts[maxDeclarationStatus] || 0;

  const isUploadFileDisabled = () => {
    if (status === TEMPORARY) return true;

    const totalUploaded =
      (goodsDeclarationEvaluation?.deliveryNotes?.length || 0) + uploadedFiles.length;

    return totalUploaded >= requiredFilesToUpload;
  };

  const handleImageRange = () => {
    const totalUploaded =
      (goodsDeclarationEvaluation?.deliveryNotes?.length || 0) + uploadedFiles.length;

    return `${totalUploaded}/${requiredFilesToUpload}`;
  };

  return (
    <>
      <div className="organisation-checklist__quarter-text--wrapper">
        <div className="organisation-checklist__quarter-text">
          <span className="organisation-checklist__number-bold">5 .</span>{' '}
          <span>{t('control_body.survey.form.fifth_step')}</span>
          <span className="organisation-checklist__quarter-text--value">
            {evaluationPeriods.join(', ')}
          </span>
        </div>
        {(!goodsDeclarationEvaluation ||
          goodsDeclarationEvaluation?.randomGoodsDeclarations?.length === 0) && (
          <div className="organisation-checklist__quarter-text--no-data">
            <span className="organisation-checklist__deviation-legend--status-disabled">
              {t('common.no_data_available')}
            </span>
          </div>
        )}
      </div>
      <div className="organisation-checklist__quarter-text--wrapper">
        <span className="organisation-checklist__quarter-text--description">
          {t('control_body.survey.form.fifth_step_details')}
        </span>
      </div>
      <Card className="organisation-checklist__deviation-card">
        {goodsDeclarationEvaluation?.randomGoodsDeclarations?.length > 0 ? (
          <Fragment>
            <div className="organisation-checklist__deviation-card-vat-percentage">
              <Field name="vat_percentage" initialValue={goodsDeclarationEvaluation?.vatPercentage}>
                {({ input, meta }) => (
                  <Input
                    size="tiny"
                    type="number"
                    label={t('control_body.survey.form.vat_percentage')}
                    placeholder={t('control_body.survey.form.vat_percentage')}
                    required={true}
                    fullWidth={true}
                    disabled={isCertifier}
                    touched={!meta.dirtySinceLastSubmit}
                    error={errorMessage?.goodsDeclarationEvaluationVatPercentage}
                    {...input}
                  />
                )}
              </Field>
              <Field
                name="vat_percentage_reason"
                initialValue={goodsDeclarationEvaluation?.vatPercentageReason}
              >
                {({ input }) => (
                  <SelectBox
                    size="tiny"
                    width="full"
                    label={t('shared.reason')}
                    isClearable={true}
                    options={customVatReasonsOptions}
                    value={customVatReasonsOptions.find(option => option.value === input.value)}
                    isDisabled={isCertifier}
                    selectedValue={input.label}
                    onChange={e => input.onChange(e.value)}
                    errorMsg={errorMessage?.goodsDeclarationEvaluationVatPercentageReason}
                  />
                )}
              </Field>
            </div>
            <Field
              name="comment_to_change_vat_percentage"
              initialValue={goodsDeclarationEvaluation?.commentToChangeVatPercentage}
            >
              {({ input, meta }) => (
                <Textarea
                  className={'organisation-checklist__deviation-card-vat-percentage-comment'}
                  label={t('control_body.survey.form.vat_percentage_comment')}
                  placeholder={t('control_body.survey.form.comments_placeholder')}
                  disabled={isCertifier}
                  touched={meta.touched}
                  error={errorMessage?.goodsDeclarationEvaluationCommentToChangeVatPercentage}
                  {...input}
                />
              )}
            </Field>
            {goodsDeclarationEvaluation?.randomGoodsDeclarations.map(goods => (
              <RandomGoodsDeclarationInfo
                randomGoodsDeclaration={goods}
                isChecklist={true}
                setEvaluationPeriods={setEvaluationPeriods}
                handleVerifyInputAmount={handleVerifyInputAmount}
                status={status}
                isCertifier={isCertifier}
              />
            ))}
          </Fragment>
        ) : (
          <span className="organisation-checklist__deviation-legend--status-disabled">
            {t('common.no_data_available')}
          </span>
        )}

        <Field name="random_declarations_comment" initialValue={randomDeclarationsComment || ''}>
          {({ input }) => (
            <Textarea
              className={classNames({
                'organisation-checklist__quarter-comment': goodsDeclarationEvaluation,
              })}
              label={
                isCertifier
                  ? t('control_body.survey.form.auditor_comments')
                  : t('control_body.survey.form.comments')
              }
              placeholder={t('control_body.survey.form.comments_placeholder')}
              disabled={isCertifier || status === TEMPORARY}
              {...input}
            />
          )}
        </Field>
        {goodsDeclarationEvaluation && (
          <div className="organisation-checklist__category">
            {hasAnyGoodsDeclaration && (
              <>
                <div>
                  {t('control_body.survey.form.delivery_list_items', {
                    count: requiredFilesToUpload,
                  })}
                </div>
                <div className="organisation-checklist__category-description">
                  {t('control_body.survey.form.recorded_values')}
                </div>
                <ul>
                  <li>{t('control_body.survey.form.organisation.list_item1')}</li>
                  <li>{t('control_body.survey.form.organisation.list_item2')}</li>
                  <li>{t('control_body.survey.form.organisation.list_item3')}</li>
                  {hasAnyBadStatus && (
                    <li> {t('control_body.survey.form.organisation.list_item4')}</li>
                  )}
                  {hasAnyBadStatus && (
                    <li>{t('control_body.survey.form.organisation.list_item5')}</li>
                  )}
                </ul>
              </>
            )}

            {hasAnyGoodsDeclaration && (
              <div className="organisation-checklist__upload-wrapper">
                <div className="organisation-checklist__upload-wrapper--header">
                  {t('control_body.survey.form.uploaded_files')}
                </div>
                <div className="organisation-checklist__upload-btn">
                  <div className="organisation-checklist__upload-btn--count">
                    {handleImageRange()} {t('control_body.survey.form.uploaded')}
                  </div>
                  {!isCertifier && (
                    <ChooseFile
                      showText={false}
                      disabled={isUploadFileDisabled()}
                      onChange={file => handleUploadFiles(file)}
                      labelText={
                        <>
                          <Icon
                            className="choose-file__contract-delete--icon"
                            name="uploadFile"
                            size="tiny"
                          />
                          <span className="choose-file__contract-delete--text">
                            {t('control_body.survey.form.upload_photo')}
                          </span>
                        </>
                      }
                    />
                  )}
                </div>
              </div>
            )}
            <div
              className={classNames({
                'organisation-checklist__upload-list--wrapper': hasAnyBadStatus || hasAnyAvgStatus,
              })}
            >
              {goodsDeclarationEvaluation &&
                hasAnyGoodsDeclaration &&
                goodsDeclarationEvaluation?.deliveryNotes &&
                goodsDeclarationEvaluation?.deliveryNotes.map(note => (
                  <div
                    className={classNames({
                      'col-6 col-bleed': hasAnyBadStatus || hasAnyAvgStatus,
                    })}
                  >
                    <Card className="organisation-checklist__upload-list--card">
                      <div className="organisation-checklist__upload-list">
                        <div className="organisation-checklist__upload-list--name">
                          <Icon name="photo" color="tertiary" size="normal" />
                          <span className="organisation-checklist__upload-list--name__truncate">
                            {note?.filename}
                          </span>
                        </div>
                        <div>{bytesToMegabytes(note?.sizeInByte)} MB</div>
                        {isFetching ? (
                          <Spinner size="tiny" color="success" bgColor="none" />
                        ) : (
                          <div className="organisation-checklist__audio-card--icon">
                            <a href={note?.url} target="_blank" rel="noreferrer">
                              <IconButton
                                className="topnav__back-button"
                                icon={
                                  <Icon
                                    className="choose-file__contract-delete--icon"
                                    name="downloadFile"
                                    size="normal"
                                  />
                                }
                              />
                            </a>

                            <IconButton
                              className="topnav__back-button"
                              color="danger"
                              icon={
                                <Icon
                                  className="choose-file__contract-delete--icon"
                                  name="delete"
                                  size="normal"
                                  color="danger"
                                />
                              }
                              disabled={isCertifier}
                              onClick={() => handleImageDelete(note.id)}
                            />
                          </div>
                        )}
                      </div>
                    </Card>
                  </div>
                ))}

              {uploadedFiles.map(uploadedFile => (
                <div
                  className={classNames({
                    'col-6 col-bleed': hasAnyBadStatus || hasAnyAvgStatus,
                  })}
                >
                  <Card className="organisation-checklist__upload-list--card">
                    <div className="organisation-checklist__upload-list">
                      <div className="organisation-checklist__upload-list--name">
                        <Icon name="photo" color="tertiary" size="normal" />
                        <span className="organisation-checklist__upload-list--name__truncate">
                          {uploadedFile.file.name}
                        </span>
                      </div>
                      <div>{bytesToMegabytes(uploadedFile?.file.size)} MB</div>
                      {!hasAnyGoodsDeclaration && (
                        <IconButton
                          className="topnav__back-button"
                          icon={
                            <Icon
                              className="choose-file__contract-delete--icon"
                              name="downloadFile"
                              size="normal"
                            />
                          }
                          disabled={true}
                        />
                      )}
                      <IconButton
                        className="topnav__back-button"
                        color="danger"
                        icon={
                          <Icon
                            className="choose-file__contract-delete--icon"
                            name="delete"
                            size="normal"
                            color="danger"
                          />
                        }
                        onClick={() => {
                          const filteredFiles = uploadedFiles.filter(
                            file => file.id !== uploadedFile.id,
                          );
                          handleUploadFilteredFiles(filteredFiles);
                        }}
                      />
                    </div>
                  </Card>
                </div>
              ))}
            </div>
            <hr className="organisation-checklist__checkbox-horizontal-line organisation-checklist__checkbox-horizontal-line--upload" />
            {goodsDeclarationEvaluation && hasAnyGoodsDeclaration && (
              <Field
                name="delivery_notes_comment"
                initialValue={goodsDeclarationEvaluation?.deliveryNotesComment || ''}
              >
                {({ input }) => (
                  <Textarea
                    className="organisation-checklist__quarter-comment"
                    label={
                      isCertifier
                        ? t('control_body.survey.form.auditor_comments')
                        : t('control_body.survey.form.comments')
                    }
                    placeholder={t('control_body.survey.form.comments_placeholder')}
                    disabled={isCertifier || status === TEMPORARY}
                    {...input}
                  />
                )}
              </Field>
            )}
          </div>
        )}
        {isAuditor &&
          goodsDeclarationEvaluation &&
          Object.keys(goodsDeclarationEvaluation).length > 0 && (
            <div className="organisation-checklist__auditor-acknowledgement">
              <Field
                name="auditorAcknowledgement"
                initialValue={boolToYesNo(goodsDeclarationEvaluation?.auditorAcknowledgement)}
              >
                {({ input, meta }) => (
                  <YesNoInput
                    {...input}
                    error={meta.submitError}
                    label={t('control_body.survey.form.auditor_acknowledgement')}
                    disabled={status === TEMPORARY}
                  />
                )}
              </Field>
            </div>
          )}
        {goodsDeclarationEvaluation &&
          Object.keys(goodsDeclarationEvaluation).length > 0 &&
          isCertifier && (
            <div className="organisation-checklist__auditor-acknowledgement organisation-checklist__certifier-acknowledgement">
              <Field
                name="certifierAcknowledgement"
                initialValue={
                  goodsDeclarationEvaluation.certifierAcknowledgement !== null
                    ? boolToYesNo(goodsDeclarationEvaluation.certifierAcknowledgement)
                    : boolToYesNo(goodsDeclarationEvaluation.auditorAcknowledgement)
                }
              >
                {({ input, meta }) => (
                  <YesNoInput
                    {...input}
                    error={meta.submitError}
                    label={t('control_body.survey.form.auditor_acknowledgement')}
                    disabled={status === TEMPORARY}
                  />
                )}
              </Field>
            </div>
          )}
        {isCertifier && (
          <div className="organisation-checklist__certifier-audio">
            <Field
              name="certifierComment"
              initialValue={goodsDeclarationEvaluation.certifierComment || ''}
            >
              {({ input }) =>
                formState.values.certifierAcknowledgement !== null &&
                formState.values.certifierAcknowledgement !==
                  boolToYesNo(goodsDeclarationEvaluation.auditorAcknowledgement) && (
                  <Textarea
                    className="organisation-checklist__certifier-audio--field"
                    label={t('control_body.survey.form.reason_of_change')}
                    placeholder={t('control_body.survey.form.comments_placeholder')}
                    {...input}
                    disabled={status === TEMPORARY}
                  />
                )
              }
            </Field>
          </div>
        )}
        {goodsDeclarationEvaluation &&
          offlineSession &&
          offlineSession?.satisfied !== null &&
          Object.keys(goodsDeclarationEvaluation).length > 0 &&
          isCertifier &&
          status === TEMPORARY &&
          isOfflineSessionsFetched &&
          goodsDeclarationEvaluation?.certifierAcknowledgement && (
            <div className="organisation-checklist__auditor-acknowledgement">
              <Field
                name="auditorAcknowledgement"
                initialValue={boolToYesNo(offlineSession?.satisfied)}
              >
                {({ input, meta }) => (
                  <YesNoInput
                    {...input}
                    error={meta.submitError}
                    label={t('control_body.survey.form.pass_random')}
                    disabled={true}
                  />
                )}
              </Field>
            </div>
          )}
        {errorMessage &&
          (errorMessage['goodsDeclarationEvaluation'] ||
            errorMessage['deliveryNotes'] ||
            errorMessage['goodsDeclarationEvaluationDeliveryNotes']) && (
            <div className="organisation-checklist__errors">
              <Icon name="invalid" color="danger" size="small" />
              <span className="organisation-checklist__errors-text">
                {errorMessage['deliveryNotes'] ||
                  errorMessage['goodsDeclarationEvaluation'] ||
                  errorMessage['goodsDeclarationEvaluationDeliveryNotes']}
              </span>
            </div>
          )}
      </Card>
    </>
  );
};

RandomDeclarationComponent.defaultProps = {
  goodsDeclarationEvaluation: {},
  uploadedFiles: [],
  errorMessage: {},
  isFetching: false,
  isAuditor: false,
  isCertifier: false,
  handleUploadFiles: () => {},
  handleUploadFilteredFiles: () => {},
  randomDeclarationsComment: null,
  status: null,
};

RandomDeclarationComponent.propTypes = {
  goodsDeclarationEvaluation: PropTypes.shape(),
  errorMessage: PropTypes.shape(),
  isFetching: PropTypes.bool,
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape()),
  handleUploadFiles: PropTypes.func,
  handleUploadFilteredFiles: PropTypes.func,
  isAuditor: PropTypes.bool,
  isCertifier: PropTypes.bool,
  randomDeclarationsComment: PropTypes.string,
  status: PropTypes.string,
  surveyId: PropTypes.number.isRequired,
};

export default RandomDeclarationComponent;
